import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/usr/src/app/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "release-notes"
    }}>{`Release notes`}</h1>
    <p>{`Date: 13.01.2025`}<br />{`
`}<strong parentName="p">{`Search query parameter added to GET List of deactivated employees endpoint in HR API`}</strong><br />{`
From now on `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees~1deactivated/get"
      }}>{`GET List of deactivated employees endpoint`}</a>{` in HR API supports new parameter - `}<inlineCode parentName="p">{`searchQuery`}</inlineCode>{`. It allows to filter employees by First Name, Last Name, Email, Phone/Cell Phone Number, Salary Identifier and SSN.`}</p>
    <hr></hr>
    <p>{`Date: 27.11.2024`}<br />{`
`}<strong parentName="p">{`PrimaryDepartmentId property added to GET List of employees endpoint response in HR API`}</strong><br />{`
From now on `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees/get"
      }}>{`GET List of employees endpoint`}</a>{` in HR API will return employee's `}<inlineCode parentName="p">{`PrimaryDepartmentId`}</inlineCode>{` (if set).`}</p>
    <hr></hr>
    <p>{`Date: 30.10.2024`}<br />{`
`}<strong parentName="p">{`Search query parameter added to GET List of employees endpoint in HR API`}</strong><br />{`
From now on `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees/get"
      }}>{`GET List of employees endpoint`}</a>{` in HR API supports new parameter - `}<inlineCode parentName="p">{`searchQuery`}</inlineCode>{`. It allows to filter employees by First Name, Last Name, Email, Phone/Cell Phone Number, Salary Identifier and SSN.`}</p>
    <hr></hr>
    <p>{`Date: 01.10.2024`}<br />{`
`}<strong parentName="p">{`Deprecation of the `}<inlineCode parentName="strong">{`X-OpenAPI-Region`}</inlineCode>{` HTTP header`}</strong><br />{`
Planday is retiring its Australia region, thus removing its multi-regional support. Previously, it was possible to specify in which region (Europe or Australia) a given HTTP request would be send to by adding a `}<inlineCode parentName="p">{`X-OpenaAPI-Region`}</inlineCode>{` HTTP header to the request.
Starting from 01.10.2024, this header will have no effect.`}</p>
    <hr></hr>
    <p>{`Date: 08.08.2023`}<br />{`
`}<strong parentName="p">{`Complete support for Skills in OpenAPI`}</strong><br />{`
New endpoints in the `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Skills"
      }}>{`HR Skills controller`}</a>{` allow you to manage portal's skills fully via OpenAPI. See previous release notes for details on how to utilise skills for scheduling.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Skills/paths/~1hr~1v1.0~1skills/post"
        }}>{`POST`}</a>{` Create a new skill`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Skills/paths/~1hr~1v1.0~1skills/put"
        }}>{`PUT`}</a>{` Update a skill`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Skills/paths/~1hr~1v1.0~1skills/delete"
        }}>{`DELETE`}</a>{` Delete a skill`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 28.07.2023`}<br />{`
`}<strong parentName="p">{`Skills support in Schedule & HR API`}</strong><br />{`
It is now possible to use the skills feature in Planday OpenAPI. Beware that skills are enabled on portals upon request. Skills allow schedule admins to effectively match employees to shifts they are qualified to take.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Skills/paths/~1hr~1v1.0~1skills/get"
        }}>{`GET`}</a>{` Get a list of skills available to be assigned to employees and shifts.`}</li>
      <li parentName="ul">{`HR endpoints let you `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees~1%7BemployeeId%7D/get"
        }}>{`GET`}</a>{` get skills assigned to an employee and allow you to `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees~1%7BemployeeId%7D/put"
        }}>{`PUT`}</a>{` update the list or assign skills during employee creation `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees/post"
        }}>{`POST`}</a>{`.`}</li>
      <li parentName="ul">{`Schedule endpoints let you `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Shifts/paths/~1scheduling~1v1.0~1shifts~1%7BshiftId%7D/get"
        }}>{`GET`}</a>{` get skills required for a shift. You can specify the skills when `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Shifts/paths/~1scheduling~1v1.0~1shifts/post"
        }}>{`POST`}</a>{` creating or `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Shifts/paths/~1scheduling~1v1.0~1shifts~1%7BshiftId%7D/put"
        }}>{`PUT`}</a>{` updating a shift. To require skills on certain shifts automatically, you can assign skills to schedule positions (`}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Positions/paths/~1scheduling~1v1.0~1positions~1%7BpositionId%7D/get"
        }}>{`GET`}</a>{`, `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Positions/paths/~1scheduling~1v1.0~1positions~1%7BpositionId%7D/put"
        }}>{`PUT`}</a>{`, `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Positions/paths/~1scheduling~1v1.0~1positions/post"
        }}>{`POST`}</a>{`).`}</li>
    </ul>
    <p><strong parentName="p">{`Supervisor field now supported in HR API`}</strong><br />{`
You can now manipulate the `}<inlineCode parentName="p">{`Supervisor`}</inlineCode>{` field via HR endpoints. Please note that the supervisor field is available on portals upon request. You can get a list of employees who can be assigned as supervisors using the dedicated endpoint which also exposes their `}<inlineCode parentName="p">{`supervisorID`}</inlineCode>{` that must be used when assigning the supervisor to another employee. The field is also available in employee's create, update and get endpoints.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees~1supervisors/get"
        }}>{`GET`}</a>{` Get a list of employees who can be assigned as supservisors using their `}<inlineCode parentName="li">{`supervisorID`}</inlineCode>{`.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees~1%7BemployeeId%7D/get"
        }}>{`GET`}</a>{` Get employee by ID endpoint now exposes `}<inlineCode parentName="li">{`supervisorID`}</inlineCode>{` and the field is also available when `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees/post"
        }}>{`POST`}</a>{` creating or `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr?version=v1.0#tag/Employee/paths/~1hr~1v1.0~1employees~1%7BemployeeId%7D/put"
        }}>{`PUT`}</a>{` updating an employee. The GET endpoint also offers a `}<inlineCode parentName="li">{`supervisorEmployeeId`}</inlineCode>{` field which lets you skip the lookup of the supervisor ID to learn the supervisor's employee ID.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 26.06.2023`}<br />{`
`}<strong parentName="p">{`Salary period support in Pay API`}</strong><br />{`
The Salary Period feature was recently rolled out to our customers worldwide and Planday's API is following suit. HR admins are now able to specify an employee's salary over a wider range of periods and are not limited to inputting a monthly value. You can get and set the new property in our `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/pay#tag/EmployeeSalaries"
      }}>{`Salary endpoints`}</a>{`. All endpoints default to the original monthly value if not specified otherwise.`}<br />{`
`}<strong parentName="p">{`New Schedule endpoints`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Shifts/paths/~1scheduling~1v1.0~1shifts~1%7BshiftId%7D/get"
        }}>{`GET`}</a>{` Get a shift by ID if you want to obtain details of a specific shift without having to page over a list of shifts in the date range.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/ShiftHistory/paths/~1scheduling~1v1.0~1shifts~1%7BshiftId%7D~1history/get"
        }}>{`GET`}</a>{` Get history of changes of a shift including modify dates and authors of the changes.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Shifts/paths/~1scheduling~1v1.0~1shifts~1%7BshiftId%7D~1employee/post"
        }}>{`POST`}</a>{` Assign an employee to a shift using a lightweight dedicated endpoint instead of the regular Update Shift endpoint.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/Shifts/paths/~1scheduling~1v1.0~1shifts~1%7BshiftId%7D~1approve/post"
        }}>{`POST`}</a>{` Approve a shift for payroll to finalise admin's last step in preparation to run payroll. This endpoint does not take punchclock data into account.`}</li>
    </ul>
    <p><strong parentName="p">{`Schedule endpoint improvements`}</strong></p>
    <ul>
      <li parentName="ul">{`All `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule?version=v1.0#tag/ShiftTypes"
        }}>{`Shift Type`}</a>{` endpoints now offer a wider range of details about the type, such as its salary code and permission for breaks.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 05.04.2023`}<br />{`
`}<strong parentName="p">{`New Revenue endpoints`}</strong><br />{`
New Revenue Unit endpoints allow your integration to manage units on a portal. POS integrations can benefit from this functionality by allowing users to simply map their departments to a 3rd party system without the need to navigate to Planday Settings to manually create Revenue units to store daily revenue data as this process can now be automated via our API.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/revenue#tag/RevenueUnit/operation/RevenueUnitPost_CreateRevenueUnit"
        }}>{`POST`}</a>{` Create a new revenue unit`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/revenue#tag/RevenueUnit/operation/RevenueUnitPut_UpdateRevenueUnit"
        }}>{`PUT`}</a>{` Update an existing revenue unit`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 14.03.2023`}<br />{`
`}<strong parentName="p">{`New HR endpoints`}</strong><br />{`
We are introducing a new `}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/hr#tag/EmployeeAccountManagement"
      }}>{`Employee Account Management`}</a>{` controller. Using this controller, you can control your employees' credentials and help them access their Planday accounts.`}<br />{`
The following endpoints are currently available in the new controller.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeeAccountManagementPost_SendInvite"
        }}>{`POST`}</a>{` Invite an employee by sending an invite email with a password reset link to their email address (username field used).`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeeAccountManagementPut_ChangeUsername"
        }}>{`PUT`}</a>{` Change a Username of an employee who wishes to log in to Planday using another email address.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeeAccountManagementPut_ResetPassword"
        }}>{`PUT`}</a>{` Reset an employee's password by sending them an email with a password reset link.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 08.03.2023`}<br />{`
`}<strong parentName="p">{`New Absence endpoints`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AdjustmentsGet_Get"
        }}>{`GET`}</a>{` Get list of Adjustments of an accrued absence account.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AdjustmentsPost_Create"
        }}>{`POST`}</a>{` Create an Adjustment on an absence account to change its balance.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 28.12.2022`}<br />{`
`}<strong parentName="p">{`New Schedule endpoints`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule#operation/ShiftTypesPost_Create"
        }}>{`POST`}</a>{` Create a shift type`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule#operation/ShiftTypesPut_Update"
        }}>{`PUT`}</a>{` Update a shift type`}</li>
    </ul>
    <p><strong parentName="p">{`New Absence endpoints`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AbsenceRequestsPut_DenyRequest"
        }}>{`PUT`}</a>{` Deny an absence request. This endpoint replaces the `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AbsenceRequestsPut_CancelRequest"
        }}>{`Cancel request`}</a>{` endpoint which is going to be deprecated.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AbsenceRecordsPut_DeclineRecord"
        }}>{`PUT`}</a>{` Decline an absence record`}</li>
    </ul>
    <p><strong parentName="p">{`Absence endpoint improvements`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AccountsGet_Get"
        }}>{`GET`}</a>{` Absence accounts endpoint now allows you to use pagination. Paging parameters are not required and the endpoint’s behaviour has not changed when the parameters are not included in a request.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 01.09.2022`}<br />{`
`}<strong parentName="p">{`New Absence endpoint`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/absence#operation/AbsenceRequestsPut_ApproveRequest"
        }}>{`PUT`}</a>{` Approve an absence request allows you to approve an employee's request, changing its status to approved and creating a corresponding Absence Record.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 06.04.2022`}<br />{`
`}<strong parentName="p">{`New domain - Security Group Membership`}</strong><br />{`
The Security Group Membership endpoints let you specify what access level should individual employees have.`}<br />{`
Example usecase: A new Schedule Admin joined the org using your HR integration. Create the employee's account in Planday using the HR endpoint and then assign them to the Schedule Admin security group using the new PUT endpoint to give them admin access to the portal's schedule.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/securitygroupmembership#operation/SecurityGroupsGet_GetSecurityGroups"
        }}>{`GET`}</a>{` Security groups existing on the portal.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/securitygroupmembership#operation/SecurityGroupsPut_UpdateSecurityGroupMembershipForEmployee"
        }}>{`PUT`}</a>{` Add an employee to a security group or update an existing membership.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/securitygroupmembership#operation/SecurityGroupsDelete_DeleteSecurityGroupMembershipForEmployee"
        }}>{`DELETE`}</a>{` Remove an employee from a security group to remove their elevated access.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 02.06.2021`}<br />{`
`}<strong parentName="p">{`Schedule endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/hr#operation/EmployeesGet_Get"
        }}>{`GET`}</a>{` Employee by ID added SupervisorEmployeeId to the response. The Supervisor feature is only available in Planday per request.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 21.04.2021`}<br />{`
`}<strong parentName="p">{`Schedule endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/schedule#tag/ShiftTypes/paths/~1scheduling~1v%7Bversion%7D~1shifttypes/get"
        }}>{`GET`}</a>{` Shift types added isActive (shift type status) to the response. The isActive property can also be used as a query parameter.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 12.04.2021`}<br />{`
`}<strong parentName="p">{`Schedule endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/schedule#tag/Positions/paths/~1scheduling~1v%7Bversion%7D~1positions/get"
        }}>{`GET`}</a>{` Available positions added employeeGroupId to the response.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 06.04.2021`}<br />{`
`}<strong parentName="p">{`New Schedule endpoints`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/schedule#tag/Shifts/paths/~1scheduling~1v%7Bversion%7D~1shifts/post"
        }}>{`POST`}</a>{` Shift endpoint is now available and lets you create new shifts in Schedule.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/schedule#tag/Shifts/paths/~1scheduling~1v%7Bversion%7D~1shifts~1%7BshiftId%7D/put"
        }}>{`PUT`}</a>{` Shift endpoint is now available for you to update existing scheduled shifts.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/schedule#tag/Shifts/paths/~1scheduling~1v%7Bversion%7D~1shifts~1%7BshiftId%7D/delete"
        }}>{`DELETE`}</a>{` Shift endpoint lets you delete a shift from the system.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 29.03.2021`}<br />{`
`}<strong parentName="p">{`Schedule endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://developer.planday.com/api/schedule#tag/TimeAndCost/paths/~1scheduling~1v%7Bversion%7D~1timeandcost~1%7BdepartmentId%7D/get"
        }}>{`GET`}</a>{` Time and cost of shifts endpoint now provides `}<inlineCode parentName="li">{`shiftTypeId`}</inlineCode>{` and `}<inlineCode parentName="li">{`positionId`}</inlineCode>{` in the response.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 02.12.2020`}<br />{`
`}<strong parentName="p">{`Pay endpoint improvements`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/pay#tag/PayRates/paths/~1pay~1v%7Bversion%7D~1payrates~1employeeGroups~1%7BemployeeGroupId%7D~1employees~1%7BemployeeId%7D/get"
        }}>{`GET`}</a>{` Employee's pay rate in an employee group endpoint now provides a salary code in its response.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/pay#tag/PayRates/paths/~1pay~1v%7Bversion%7D~1payrates~1employees~1%7BemployeeId%7D/get"
        }}>{`GET`}</a>{` Employee's pay rate in all their employee groups endpoint now provides a salary code in its response.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/pay#tag/PayRates/paths/~1pay~1v%7Bversion%7D~1payrates~1employeeGroups~1%7BemployeeGroupId%7D/put"
        }}>{`PUT`}</a>{` Update employees pay rates in an employee group endpoint now lets you specify a new salary code.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 29.10.2020`}<br />{`
`}<strong parentName="p">{`Payroll endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/payroll#tag/Payroll/paths/~1payroll~1v%7Bversion%7D~1payroll/get"
        }}>{`GET`}</a>{` Payroll endpoint now provides `}<inlineCode parentName="li">{`departmentId`}</inlineCode>{` in the `}<inlineCode parentName="li">{`shiftsPayroll`}</inlineCode>{` object.`}</li>
    </ul>
    <p><strong parentName="p">{`New Schedule endpoint`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule#tag/Shifts/paths/~1scheduling~1v%7Bversion%7D~1shifts~1deleted/get"
        }}>{`GET`}</a>{` List of deleted shifts endpoint is now available in the Scheduling API.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 28.07.2020`}<br />{`
`}<strong parentName="p">{`Schedule endpoint improvements`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeesGet_GetEmployees"
        }}>{`GET`}</a>{` List of employees added `}<inlineCode parentName="li">{`street2`}</inlineCode>{` to the response.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeesGet_GetDeactivatedEmployees"
        }}>{`GET`}</a>{` List of deactivated employees added `}<inlineCode parentName="li">{`street2`}</inlineCode>{` to the response.`}</li>
    </ul>
    <hr></hr>
    <p>{`Date 10.07.2020`}<br />{`
`}<strong parentName="p">{`Pay endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/punchclock#tag/PunchClockShifts/paths/~1punchclock~1v%7Bversion%7D~1punchclockshifts/post"
        }}>{`POST`}</a>{` Create a Punch-In entry now supports punch in without a shift. `}<inlineCode parentName="li">{`employeeGroupId`}</inlineCode>{` and `}<inlineCode parentName="li">{`departmentId`}</inlineCode>{` will be mandatory for this request. A shift will get created in Planday upon approval of the punch clock entry.`}</li>
    </ul>
    <p><strong parentName="p">{`New Schedule endpoint`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule#tag/Sections/paths/~1scheduling~1v%7Bversion%7D~1sections/get"
        }}>{`GET`}</a>{` Available sections endpoint is now available in the Scheduling API`}</li>
    </ul>
    <p><strong parentName="p">{`Schedule endpoint improvements`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule#tag/Positions/paths/~1scheduling~1v%7Bversion%7D~1positions/get"
        }}>{`GET`}</a>{` Available positions added `}<inlineCode parentName="li">{`sectionId`}</inlineCode>{` to the response`}</li>
    </ul>
    <p><strong parentName="p">{`HR endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeesGet_GetEmployees"
        }}>{`GET`}</a>{` List of employees added `}<inlineCode parentName="li">{`employeeTypeId`}</inlineCode>{`, `}<inlineCode parentName="li">{`salaryIdentifier`}</inlineCode>{`, `}<inlineCode parentName="li">{`hiredFrom`}</inlineCode>{` and `}<inlineCode parentName="li">{`deactivationDate`}</inlineCode>{` to the response.`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/hr#operation/EmployeesGet_GetDeactivatedEmployees"
        }}>{`GET`}</a>{` List of deactivated employees added `}<inlineCode parentName="li">{`employeeTypeId`}</inlineCode>{`, `}<inlineCode parentName="li">{`salaryIdentifier`}</inlineCode>{`, `}<inlineCode parentName="li">{`hiredFrom`}</inlineCode>{` to the response`}</li>
    </ul>
    <p><strong parentName="p">{`Schedule endpoint improvement`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/schedule#tag/Positions/paths/~1scheduling~1v%7Bversion%7D~1positions/get"
        }}>{`GET`}</a>{` Available positions added `}<inlineCode parentName="li">{`departmentId`}</inlineCode>{` to the response.`}</li>
    </ul>
    <p><strong parentName="p">{`Bug fix`}</strong></p>
    <ul>
      <li parentName="ul">{`Fixed an issue where Open API returned 500 error instead of 409 when employeeId was invalid`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 20.05.2020`}<br />{`
`}<strong parentName="p">{`Documentation improvements`}</strong><br />{`
Launch of the new API documentation including following improvements`}</p>
    <ul>
      <li parentName="ul">{`Short description of all endpoint properties across all APIs`}</li>
      <li parentName="ul">{`Updated `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/gettingstarted/authorization"
        }}>{`Authentication documentation`}</a></li>
      <li parentName="ul">{`New `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/gettingstarted/overview"
        }}>{`Getting started`}</a>{` section with basic info about the API opportunities`}</li>
      <li parentName="ul">{`New `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/guides/hr-guide"
        }}>{`Guides`}</a>{` section with descriptions of some of the most well-known integration scenarios supported by the API.`}</li>
      <li parentName="ul">{`Improved overview of endpoints and and available properties`}</li>
      <li parentName="ul">{`Overview of possible `}<a parentName="li" {...{
          "href": "https://openapi.planday.com/guides/errors"
        }}>{`API Errors`}</a></li>
      <li parentName="ul">{`Postman collection and environment available for download`}</li>
    </ul>
    <hr></hr>
    <p>{`Date: 7.04.2020`}<br />{`
`}<strong parentName="p">{`New domain - Pay`}</strong><br />{`
The new API that makes it possible to read or update an employees’ hourly wage (linked to an employee group), monthly salary and allocation of monthly salaries in the schedule costs.`}</p>
    <hr></hr>
    <p>{`Date: 24.03.2020`}<br />{`
`}<strong parentName="p">{`New domain - Contract Rules`}</strong><br />{`
`}<a parentName="p" {...{
        "href": "https://openapi.planday.com/api/contractrules"
      }}>{`Contracted Rules API`}</a>{` is a new API that enables integrations to read contract rules specified in a portal and set assign contract rule on an employee.`}</p>
    <hr></hr>
    <p>{`Date: 19.03.2020`}<br />{`
`}<strong parentName="p">{`New domain - Portal`}</strong><br />{`
A portal is a space/environment of a company. A single portal is the space where all employees and departments are stored.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://openapi.planday.com/api/portal?version=v1.0"
        }}>{`GET`}</a>{` Portal information provides basic details about the Planday portal linked to the token you are using.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      